var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about-us" }, [
      _c("div", { staticClass: "container" }, [
        _c("span", { staticClass: "title" }, [_vm._v("关于我们")]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("p", { staticClass: "first-content" }, [
            _vm._v(
              "\n                深圳市世纪融泰融资担保有限公司（简称“世纪融泰”）成立于2010年9月，注册资本金壹亿元，\n                是经深圳市经济贸易和信息化委员会批准授权的融资性担保机构。\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                自成立以来，凭借良好的经营业绩和较强的资金实力，世纪融泰在发展中树立了诚信、专注、创新、共赢的良好社会形象，\n                为中小微企业提供稳定优质的担保服务，以市场化的高效运营模式满足不断变化和创新的融资担保需求，\n                与众多合作机构建立了长期良好的业务合作关系。\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n            世纪融泰始终坚持顺应国家金融政策指引和市场导向，践行社会责任，专注业务创新发展，优化服务模式，\n            运用互联网和科技金融的手段不断完善风控体系和产品业务，为客户提供更专业、高效、稳定的担保服务。\n        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }