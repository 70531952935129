/**
 * @Author: hongweihu
 * @Date: 2019-12-04 16:37:23.969
 * @Last Modified by: hongweihu
 * @Last Modified time: 2024-07-05 14:23:23
 */
import AboutUs from './component/about-us';
import Banner from './component/banner';
import Business from './component/business';
import YqgFooter from './component/yqg-footer';
import YqgHeader from './component/yqg-header';
import YqgVideo from './component/yqg-video';

export default {
    name: 'Index',

    title: '深圳市世纪融泰融资担保有限公司',

    head() {
        const content = '深圳市世纪融泰融资担保有限公司是经深圳市经济贸易和信息化委员会批准授权的融资性担保机构，为中小微企业提供稳定优质的担保服务。客服热线：0755-83387905';

        return {meta: [{name: 'description', content}]};
    },

    components: {
        YqgHeader,
        Banner,
        AboutUs,
        Business,
        YqgFooter,
        YqgVideo
    }
};
