var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner container" }, [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "line-1" }, [
          _vm._v("\n            专注金融服务\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "line-2" }, [
          _vm._v("\n            创造信用财富\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "desc" }, [
        _vm._v(
          "\n        积极响应政策号召，扶持支柱产业和战略新兴产业发展，大力支持中小微企业转型升级。\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }